<template>
  <section :id="data?.blockId ?? null" :data-index="index" :data-block="data.__component" class="container mx-auto mt-20 lg:mt-28">
    <div>
      <h2 class="text-2xl md:text-3xl font-medium tracking-tighter md:max-w-md text-balance"> {{ data.title }} </h2>
      <div v-if="data.lead" class="prose prose-sm md:prose-base mt-6 md:mt-8 prose-p:mt-0" :class="{'list-checkmark' : data.ulStyle == 'checkmark'}">
        <MarkdownRenderer :markdown="data.lead" />
      </div>
    </div>
    <div class="mt-12 grid md:grid-cols-7 gap-4 md:gap-12 lg:gap-20">
      <div class="md:col-span-3">
        <ul class="grid gap-3 md:gap-4">
          <li v-for="(showcase, index) in data.showcase">
            <button class="relative group w-full leading-none text-center rounded-full cursor-pointer py-4 px-5 border-2 border-transparent
                           drop-shadow transition-all duration-300 hover:border-black"
                    :class="index === active ? 'bg-black text-white' : 'bg-white text-black'"
                    @click="active = index">
              {{showcase.anchor}}
            </button>
          </li>
        </ul>
      </div>
      <div class="md:col-span-4">
        <div v-for="(showcase, index) in data.showcase">
          <Transition enter-active-class="duration-500 ease-out"
                      enter-from-class="opacity-0"
                      enter-to-class="opacity-100"
          >
            <div v-if="index === active">
              <NuxtPicture v-if="showcase.image.data?.attributes.hash"
              :width="showcase.image.data?.attributes.width"
                :height="showcase.image.data?.attributes.height"
                           loading="lazy"
                           :src="showcase.image.data?.attributes.hash"
                           :alt="showcase.image.data?.attributes.alternativeText || ''"
                           :imgAttrs="{
                              class:'w-full rounded-2xl',
                              
                           }"
              />
            </div>
          </Transition>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import MarkdownRenderer from '../components/MarkDown.vue';
import {ref} from "vue";

const active = ref(0);
const props = defineProps<{
  data: any,
  index: any
}>();
</script>