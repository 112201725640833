<template>
  <section :id="data?.blockId ?? null" :data-index="index" :data-block="data.__component" class="relative">
    <div class="absolute top-0 right-0">
    
      <NuxtPicture 
            width="1196" height="1168"
            loading="eager"
            alt=""
            :src="`/svg/heroBg.svg`"
            :imgAttrs="{
              class:'pointer-events-none select-none',
            }"
        />

    </div>
    <div class="max-md:hidden absolute right-[6%] -top-12">
      <svg xmlns="http://www.w3.org/2000/svg" width="120" height="160" viewBox="0 0 120 160" fill="none">
        <path d="M40.0003 30.4762C56.8319 30.4762 70.4766 16.8315 70.4766 0" stroke="#070A26" stroke-width="19.0476"/>
        <path d="M70.4762 79.9999C70.4762 63.1684 56.8316 49.5237 40 49.5237" stroke="#070A26" stroke-width="19.0476"/>
        <path d="M9.52378 80.0001C9.52378 96.8316 23.1684 110.476 40 110.476" stroke="#070A26" stroke-width="19.0476"/>
        <path d="M70.4762 160C70.4762 143.168 56.8316 129.524 40 129.524" stroke="#070A26" stroke-width="19.0476"/>
        <path d="M120 89.5238C103.168 89.5238 89.5234 103.168 89.5234 120" stroke="#070A26" stroke-width="19.0476"/>
      </svg>
    </div>
    <div class="container md:mx-auto pt-12 md:pt-32 relative z-10 grid md:grid-cols-2 gap-12 md:gap-20">
      <div>
        <div class="max-w-lg">
          <h1 class="text-3xl md:text-5xl font-medium tracking-tighter text-balance"> {{ data.title }} </h1>
          <div v-if="data.lead" class="prose prose-sm md:prose-base mt-6 md:mt-8" :class="{'list-checkmark' : data.ulStyle == 'checkmark'}">
            <MarkdownRenderer :markdown="data.lead" />
          </div>
        </div>
        <div class="mt-8 flex max-md:flex-col gap-2 md:items-center">
          <Button
              v-for="button in data.buttons"
              :title="button.title"
              :link="button.href"
              :variant="button.type"
              :size="button.size"
              :arrow="button.arrow"
          />
        </div>
      </div>
      <div class="w-full">
        <div class="w-full md:w-[calc(50vw-40px)]">
          <NuxtPicture v-if="data.image.data?.attributes.hash"
                       sizes="100vw md:1800px"
                       :src="data.image.data?.attributes.hash"
                       :alt="data.image.data?.attributes.alternativeText || ''"
                       :imgAttrs="{
                         
                          class:'w-full drop-shadow-lg rounded-md rounded-xl'
                       }"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import Button from "../components/components/Button.vue";
import MarkdownRenderer from '../components/MarkDown.vue';

const props = defineProps<{
  data: any,
  index: any
}>();


import { useHead } from 'nuxt/app';
 // Set up the head using useHead
useHead({
  link: [
    {
      rel: 'preload',
      as: 'image',
      href: '/svg/heroBg.svg', // Adjust the path to your image
    },
  ],
});

</script>
