<template>
  <section :id="data?.blockId ?? null" :data-index="index" :data-block="data.__component" class="container mx-auto mt-32">
    <div class="max-w-md mb-2" v-if="data.title">
      <h2 class="font-medium text-3xl text-balance">{{ data.title }}</h2>
    </div>
    <div class="grid lg:grid-cols-2 gap-12 lg:gap-20">
      <div class="relative lg:pb-20">
        <div class="max-lg:hidden absolute bottom-0 left-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="120" height="80" viewBox="0 0 120 80" fill="none">
            <path d="M30.4762 40.0002C30.4762 23.1686 16.8315 9.52393 0 9.52393" stroke="#070A26" stroke-width="19.0476"/>
            <path d="M80 9.52378C63.1685 9.52378 49.5238 23.1684 49.5238 40" stroke="#070A26" stroke-width="19.0476"/>
            <path d="M80 70.4762C96.8315 70.4762 110.476 56.8316 110.476 40" stroke="#070A26" stroke-width="19.0476"/>
          </svg>
        </div>
        <div class="max-w-md" v-if="data.content">
          <div class="prose" :class="{'list-checkmark' : data.ulStyle == 'checkmark'}" v-if="data.content"><MarkdownRenderer :markdown="data.content" /></div>
        </div>
      </div>
      <div>
        <div class="mt-5">
          <div class="border-t first:border-t-0 border-t-border pt-4 first:pt-0" v-for="(question, index) in data.questions">
            <div @click="open === index ? open = -1 : open = index" class="mb-4 flex items-center justify-between cursor-pointer">{{ question.question }} <span class="transition-transform duration-500" :class="open === index ? 'rotate-45' : ''"><Icon :name="'Plus'" /></span></div>
            <Collapse :when="open === index" class="v-collapse">
              <div><div class="prose prose-p:mt-0" v-if="question.answer"><MarkdownRenderer :markdown="question.answer" /></div></div>
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style>
.v-collapse {
  transition: height 500ms cubic-bezier(0.33, 1, 0.68, 1);
}
</style>

<script setup lang="ts">
import Icon from "../components/components/Icon.vue";
import MarkdownRenderer from '../components/MarkDown.vue';
import {Collapse} from 'vue-collapsed';
import {ref} from "vue";

const open = ref(-1);

const props = defineProps<{
  data: any,
    index: any
}>();
</script>