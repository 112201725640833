import Text from "../components/dynamicBlocks/Text.vue";
import BentoBoxIconList from "../components/dynamicBlocks/BentoBoxIconList.vue";
import BentoBoxImageAndText from "../components/dynamicBlocks/BentoBoxImageAndText.vue";
import BentoBoxMedia from "../components/dynamicBlocks/BentoBoxMedia.vue";
import CallToAction from "../components/dynamicBlocks/CallToAction.vue";
import Cards from "../components/dynamicBlocks/Cards.vue";
import ContactForm from "../components/dynamicBlocks/ContactForm.vue";
import DemoBooking from "../components/dynamicBlocks/DemoBooking.vue";
import Divider from "../components/dynamicBlocks/Divider.vue";
import DownloadForm from "../components/dynamicBlocks/DownloadForm.vue";
import Employees from "../components/dynamicBlocks/Employees.vue";
import Faq from "../components/dynamicBlocks/Faq.vue";
import FeaturedLinks from "../components/dynamicBlocks/FeaturedLinks.vue";
import Hero from "../components/dynamicBlocks/Hero.vue";
import HeroFrontpage from "../components/HeroFrontpage.vue";
import IconGrid from "../components/dynamicBlocks/IconGrid.vue";
import LogoCloud from "../components/dynamicBlocks/LogoCloud.vue";
import MediaAndText from "../components/dynamicBlocks/MediaAndText.vue";
import MediaAndTextBulletList from "../components/dynamicBlocks/MediaAndTextBulletList.vue";
import MediaAndTextOverflowMedia from "../components/dynamicBlocks/MediaAndTextOverflowMedia.vue";
import MediaAndTextOverlay from "../components/dynamicBlocks/MediaAndTextOverlay.vue";
import MediaAndTextSplitView from "../components/dynamicBlocks/MediaAndTextSplitView.vue";
import Pricing from "../components/dynamicBlocks/Pricing.vue";
import Showcase from "../components/dynamicBlocks/Showcase.vue";
import Testimonial from "../components/dynamicBlocks/Testimonial.vue";
import TestimonialEmployees from "../components/dynamicBlocks/TestimonialEmployees.vue";
import ThankYou from "../components/dynamicBlocks/ThankYou.vue";

const componentImports = {
    Text,
    BentoBoxIconList,
    BentoBoxImageAndText,
    BentoBoxMedia,
    CallToAction,
    Cards,
    ContactForm,
    DemoBooking,
    Divider,
    DownloadForm,
    Employees,
    Faq,
    FeaturedLinks,
    Hero,
    HeroFrontpage,
    IconGrid,
    LogoCloud,
    MediaAndText,
    MediaAndTextBulletList,
    MediaAndTextOverflowMedia,
    MediaAndTextOverlay,
    MediaAndTextSplitView,
    Pricing,
    Showcase,
    Testimonial,
    TestimonialEmployees,
    ThankYou,
};


export default componentImports;
