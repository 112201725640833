<template>
  <section :id="data?.blockId ?? null" :data-index="index" :data-block="data.__component" class="relative">
    <div class="absolute top-0 right-0">
      <NuxtPicture 
      width="539" height="557"
            loading="eager"
            alt=""
            :src="`/svg/pricingBg.svg`"
            :imgAttrs="{
              class:'pointer-events-none select-none',
            }"
        />
    </div>
    <div class="container md:mx-auto pt-12 md:pt-32 relative z-10">
      <div class="max-w-3xl">
        <h1 class="text-3xl md:text-5xl font-medium tracking-tighter text-balance"> {{ data.title }} </h1>
        <div class="max-w-md mt-6 md:mt-8">
          <div v-if="data.lead" class="prose-sm">
            <MarkdownRenderer :markdown="data.lead" />
          </div>
        </div>
      </div>
      <div class="grid lg:grid-cols-10 gap-6 mt-8">
        <div class="bg-white border border-grey rounded-2xl lg:my-20 lg:col-span-3">
          <div class="py-12 px-10 border-b border-b-grey">
            <div >
              <span class="uppercase font-medium text-xxs">{{ data.pricing1.pretitle }}</span>
              <h3 class="font-medium text-2xl mt-6 text-balance">{{ data.pricing1.title }}</h3>
              <div v-if="data.pricing1.description" class="prose-sm mt-8">
                <MarkdownRenderer :markdown="data.pricing1.description" />
              </div>
            </div>
            <NuxtLink class="block font-medium p-[15px] border border-black rounded-full text-center leading-3 mt-8" :to="data.pricing1.buttonLink">
              {{ data.pricing1.buttonTitle }}
            </NuxtLink>
          </div>
          <div class="py-12 px-10">
            <ul class="max-w-[240px] grid gap-4">
              <li class="flex items-start gap-3 text-sm" v-for="item in data.sellingPoints1">
              <span>
                <svg v-if="item.included" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <circle cx="10" cy="10" r="10" fill="#A5F898"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7889 6.34574C14.0069 6.54221 14.0283 6.87672 13.8372 7.09941L8.55914 13.2493C8.23718 13.6244 7.64512 13.585 7.3757 13.1705L5.67446 10.5533C5.47626 10.2483 5.5527 9.84146 5.84806 9.62925C6.13201 9.42526 6.52431 9.46948 6.75571 9.73157L8.12154 11.2785C8.13929 11.2986 8.17034 11.2995 8.18918 11.2804L13.0423 6.3674C13.2454 6.16183 13.5742 6.15229 13.7889 6.34574Z" fill="#070A26"/>
                </svg>
                <svg v-if="!item.included" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <circle cx="10" cy="10" r="10" fill="#EEF1F2"/>
                  <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M13.7889 6.34574C14.0069 6.54221 14.0283 6.87672 13.8372 7.09941L8.55914 13.2493C8.23718 13.6244 7.64512 13.585 7.3757 13.1705L5.67446 10.5533C5.47626 10.2483 5.5527 9.84146 5.84806 9.62925C6.13201 9.42526 6.52431 9.46948 6.75571 9.73157L8.12154 11.2785C8.13929 11.2986 8.17034 11.2995 8.18918 11.2804L13.0423 6.3674C13.2454 6.16183 13.5742 6.15229 13.7889 6.34574Z" fill="#070A26"/>
                </svg>
              </span>
                {{ item.sellingPoint }}
              </li>
            </ul>
          </div>
        </div>
        <div class="bg-black py-12 px-10 rounded-2xl relative lg:col-span-4">
          <span class="absolute top-10 right-10 bg-mint p-2 rounded text-xxs uppercase font-medium leading-3">popular</span>
          <div class="text-white ">
            <span class="uppercase font-medium text-xxs text-mint">{{ data.pricing2?.pretitle }}</span>
            <h3 class="font-medium text-2xl mt-6 text-balance">{{ data.pricing2?.title }}</h3>
            <div v-if="data.pricing2?.description" class="prose-sm mt-8 prose-p:text-white/80">
              <MarkdownRenderer :markdown="data.pricing2?.description" />
            </div>
          </div>
          <NuxtLink v-if="data.pricing2.buttonLink" class="block font-medium p-[15px] border border-white text-white rounded-full text-center leading-3 mt-8" :to="data.pricing2.buttonLink">
            {{ data.pricing2.buttonTitle }}
          </NuxtLink>

          <div class="w-full overflow-x-hidden text-white">

            <HubspotForm
            v-if="!data.formCraftsUrl && data.hubspotFormId"
              :id="data.hubspotFormId"
            />

           <iframe v-if="data.formCraftsUrl" :src='data.formCraftsUrl' width='542' height='570' class="w-full"></iframe>

          </div>
          
        </div>
        <div class="bg-white border border-grey rounded-2xl lg:my-20 lg:col-span-3">
          <div class="py-12 px-10 border-b border-b-grey">
            <div >
              <span class="uppercase font-medium text-xxs">{{ data.pricing3?.pretitle }}</span>
              <h3 class="font-medium text-2xl mt-6 text-balance">{{ data.pricing3?.title }}</h3>
              <div v-if="data.pricing3?.description" class="prose-sm mt-8">
                <MarkdownRenderer :markdown="data.pricing3?.description" />
              </div>
            </div>
            <NuxtLink class="block font-medium p-[15px] border border-black rounded-full text-center leading-3 mt-8" :to="data.pricing3?.buttonLink">
              {{ data.pricing3?.buttonTitle }}
            </NuxtLink>
          </div>
          <div class="py-12 px-10">
            <ul class="max-w-[240px] grid gap-4">
              <li class="flex items-start gap-3 text-sm" v-for="item in data.sellingPoints3">
              <span>
                <svg v-if="item.included" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <circle cx="10" cy="10" r="10" fill="#A5F898"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7889 6.34574C14.0069 6.54221 14.0283 6.87672 13.8372 7.09941L8.55914 13.2493C8.23718 13.6244 7.64512 13.585 7.3757 13.1705L5.67446 10.5533C5.47626 10.2483 5.5527 9.84146 5.84806 9.62925C6.13201 9.42526 6.52431 9.46948 6.75571 9.73157L8.12154 11.2785C8.13929 11.2986 8.17034 11.2995 8.18918 11.2804L13.0423 6.3674C13.2454 6.16183 13.5742 6.15229 13.7889 6.34574Z" fill="#070A26"/>
                </svg>
                <svg v-if="!item.included" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <circle cx="10" cy="10" r="10" fill="#EEF1F2"/>
                  <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M13.7889 6.34574C14.0069 6.54221 14.0283 6.87672 13.8372 7.09941L8.55914 13.2493C8.23718 13.6244 7.64512 13.585 7.3757 13.1705L5.67446 10.5533C5.47626 10.2483 5.5527 9.84146 5.84806 9.62925C6.13201 9.42526 6.52431 9.46948 6.75571 9.73157L8.12154 11.2785C8.13929 11.2986 8.17034 11.2995 8.18918 11.2804L13.0423 6.3674C13.2454 6.16183 13.5742 6.15229 13.7889 6.34574Z" fill="#070A26"/>
                </svg>
              </span>
                {{ item.sellingPoint }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
  import MarkdownRenderer from '../components/MarkDown.vue';
  import HubspotForm from '../components/components/HubspotForm.vue';
  
  const props = defineProps<{
    data: any,
  index: any
  }>();

  import { useHead } from 'nuxt/app';
 // Set up the head using useHead
useHead({
  link: [
    {
      rel: 'preload',
      as: 'image',
      href: '/svg/pricingBg.svg', // Adjust the path to your image
    },
  ],
});

</script>