<template>
  <div class="hbspt-form sticky top-24" :id="'hbspt-form-'+id+'-'+index">
    <div class="absolute bg-white/5 rounded-lg h-full w-full z-0 top-0 left-0"></div>
    <div class="flex items-center justify-center relative h-full min-h-64 text-current">
    <svg class="animate-spin -ml-1 mr-3 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
    <span class="text-sm text-current">Initializing ...</span>
  </div>
  </div>
</template>


<script setup lang="ts">
  
  import { onMounted } from 'vue';

  const props = defineProps({
    id: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: '0',
    }
  });

  onMounted(() => {
    if (process.client) {
      loadHubspotForm(props.id, props.index);
    }
  });

  function loadHubspotForm(id: string, index: number) { // Accept id as a parameter
    window.hsFormsOnReady = window.hsFormsOnReady || [];
    window.hsFormsOnReady.push(() => {
      hbspt.forms.create({
        portalId: "26139321",
        formId: id,
        target: '#hbspt-form-'+id+'-'+index,
        region: "eu1",
      });
    });

  }
</script>
