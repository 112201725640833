<template>
  <div class="meetings-iframe-container" :data-src="url"></div>
</template>


<script setup lang="ts">
  
  import { onMounted } from 'vue';

  const props = defineProps({
    url: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: '0',
    }
  });

  const loadHubSpotScript = () => {
  const scriptId = 'hubspot-meetings-script';

  if (!document.getElementById(scriptId)) {
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
    script.type = 'text/javascript';
    script.async = true;
    document.body.appendChild(script);
  }
};

onMounted(() => {
  if (props.url) {
    loadHubSpotScript();
  }
});
</script>
